import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import { AccountService } from "../../api/account";
import { Config } from "../../utils/config";
import { inferError } from "../../utils/inferError";

const accountService = new AccountService(Config.apiUrl);

export const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    (async () => {
      try {
        const url = await accountService.login();
        window.location.replace(url);
      } catch (error: any) {
        const err = inferError(error);
        enqueueSnackbar(err.errorMessage, { variant: "error" });
      }
    })();
  }, [enqueueSnackbar]);

  return <></>;
};
