import React, { useContext, useEffect } from "react";
import { useSnackbar } from "notistack";

import { AccountService, ORIGINAL_STATE } from "../../api/account";
import { Config } from "../../utils/config";
import { GlobalContext } from "../../context/Global";
import { inferError } from "../../utils/inferError";
import { useNavigate, useSearchParams } from "react-router-dom";

const accountService = new AccountService(Config.apiUrl);

export const Callback: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAccountInfo } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      if (code && state) {
        try {
          const originalState = localStorage.getItem(ORIGINAL_STATE);
          const email = await accountService.callback({
            code: code,
            state: state,
            originalState: originalState,
          });
          setAccountInfo({ email: email, status: "valid" });
          navigate("/");
        } catch (error: any) {
          const err = inferError(error);
          enqueueSnackbar(err.errorMessage, {
            variant: "error",
            preventDuplicate: true,
          });
          setAccountInfo((prev) => {
            return { email: prev.email, status: "invalid" };
          });
        }
      }
    })();
  }, [enqueueSnackbar, navigate, searchParams, setAccountInfo]);

  return <></>;
};
