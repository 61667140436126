import React from "react";
import { Route, Routes } from "react-router-dom";

import { Dashboard } from "../Dashboard/Dashboard";
import { AppEntity } from "../../models/app";

interface Props {
  apps: AppEntity[];
}

export const Router: React.FC<Props> = ({ apps }) => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard appList={apps} />} />
    </Routes>
  );
};
