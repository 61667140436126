import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { AccountService } from "../../api/account";
import { AppService } from "../../api/app";
import { GlobalContext } from "../../context/Global";
import { AppEntity } from "../../models/app";
import { Config } from "../../utils/config";
import { getCurrentUserEmail } from "../../utils/session";
import { Callback } from "../Account/Callback";
import { Login } from "../Account/Login";
import { Router } from "./Router";

const accountService = new AccountService(Config.apiUrl);
const appService = new AppService(Config.apiUrl);

export const Session: React.FC = () => {
  const { accountInfo, setAccountInfo } = useContext(GlobalContext);
  const [apps, setApps] = useState<AppEntity[]>([]);
  const accountInfoDep = JSON.stringify(accountInfo);

  useEffect(() => {
    (async () => {
      try {
        const session = await accountService.checkSession();
        const email = getCurrentUserEmail(session);
        const apps = await appService.getApps();
        setApps(apps);
        setAccountInfo({ email: email, status: "valid" });
      } catch (error) {
        setAccountInfo((prev) => {
          return { email: prev.email, status: "invalid" };
        });
      }
    })();
  }, [setAccountInfo, accountInfoDep]);

  if (!accountInfo.status) {
    return <span></span>;
  }
  switch (accountInfo.status) {
    case "valid":
      return <Router apps={apps} />;
    case "invalid":
    default:
      return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      );
  }
};
