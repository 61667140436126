import React, { FunctionComponent, useContext } from "react";

import styles from "./css/Header.module.css";
import { GlobalContext } from "../../../../context/Global";
import {
  FrameIcon,
  PlatformLogoIcon,
  ReloadIcon,
  UserIcon,
} from "../../../../assets/header";

export const Header: FunctionComponent = () => {
  const { accountInfo } = useContext(GlobalContext);

  const deleteLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <header className={styles.header}>
      <div className={styles.WTWLogoDiv}>
        <PlatformLogoIcon className={styles.platformLogoIcon} />
        <div className={styles.appTittleDiv}>
          <b className={styles.appName}>Applications Platform</b>
          <div className={styles.logoAlgorathDiv}>
            <div className={styles.logoAlgorathDiv1}>
              <div className={styles.poweredByDiv}>Powered by </div>
              <FrameIcon className={styles.appFrameIcon} />
              <div className={styles.poweredByDiv}>
                {" "}
                v{process.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fullLogDiv}>
        <div className={styles.userDiv}>
          <UserIcon className={styles.userLogoIcon} />
          <div className={styles.userInformationDiv}>
            <p className={styles.description}>Welcome</p>
            <p className={styles.identificationP} id="Body medium">
              {accountInfo.email}
            </p>
          </div>
        </div>
        <button
          className={styles.reloadCredentials}
          onClick={deleteLocalStorage}
        >
          <ReloadIcon className={styles.reloadIcon} />
          <div className={styles.reloadText}>Reload credentials</div>
        </button>
      </div>
    </header>
  );
};
